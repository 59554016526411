import React, { useEffect, useState } from "react";
import "./App.css";
import { ChakraProvider, useColorModeValue } from "@chakra-ui/react";
import { Routes, Route, BrowserRouter as Router, } from 'react-router-dom'
import Annotation from "./pages/annotation";
import Login from "./pages/login";
import Splash from "./pages/splash";
import NoMatch from "./pages/404";

import RedirectAuth from "./components/RedirectAuth";

import { auth } from './firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { connect } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './redux'
import { GET_USER, GET_QUERY_DATE } from './redux/userRedux'

function Intermediate(props) {

  const [user, setUser] = useState(-1)
  const background = useColorModeValue("gray.100", "gray.800")

  useEffect(() => {

    let authListener = onAuthStateChanged(auth, user => {
      if (!user) {
        console.log("USER HAS BEEN SET")
      }
      setUser(user)
      props.dispatch({ type: GET_USER, payload: { user } })
    })

    const dateHere = new Date().getTime()
    const dateObj = new Date(dateHere)
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const queryDate = day + '-' + month + '-' + year

    props.dispatch({
      type: GET_QUERY_DATE,
      payload: {
        queryDate
      }
    })

    return () => {
      authListener()
    }

  }, [])

  if (user === -1) {
    return (
      <ChakraProvider bg={background}>
        <Splash />
      </ChakraProvider>
    )
  }

  return (
    <ChakraProvider bg={background}>
      <Router>
        <Routes>
          {/* <Route path="/" element={<RedirectAuth><Dashboard /></RedirectAuth>} /> */}
          {/* {!props.user.user && <Route path="/login" element={<Login />} />} */}
          <Route path='/login' element={<Login/>} />
          <Route path="/" element={<RedirectAuth><Annotation /></RedirectAuth>} />
          <Route path='*' element={<NoMatch />} />
        </Routes>
      </Router>
    </ChakraProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
}
const ReduxIntermediate = connect(mapStateToProps)(Intermediate)

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ReduxIntermediate />
      </PersistGate>
    </Provider>
  );
}

export default App;
