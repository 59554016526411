import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCAIzIbLPieT6Arib8Ly1dqZmdS4P99QS0",
  authDomain: "flok-90c44.firebaseapp.com",
  projectId: "flok-90c44",
  storageBucket: "flok-90c44.appspot.com",
  messagingSenderId: "860910331942",
  appId: "1:860910331942:web:a56b7f8d942c17615b5ee7",
  measurementId: "G-YKYF61KBN5"
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

export { auth, db };