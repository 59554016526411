import {createStore,combineReducers,applyMiddleware} from 'redux'
import {persistStore,persistReducer} from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import userRedux from './userRedux'
import contentRedux from './contentRedux'

const rootReducer = combineReducers ({
    user: userRedux,
    content: contentRedux,
})

const persistConfig = {
    key:'root',
    storage,
    whitelist: ['user']
}

const pReducer = persistReducer(persistConfig, rootReducer)

export const store =  createStore(pReducer,applyMiddleware(thunk))
export const persistor = persistStore(store)