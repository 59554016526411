import React from "react";
import {
    Flex,
    useColorModeValue,
    Heading
} from "@chakra-ui/react";

export default function NoMatch() {
    return (
        <Flex
            minH={"100vh"}
            align={"center"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <Heading as="h4" size="md">
                404 - Not Found
            </Heading>
        </Flex>
    );
}
