import React from "react";
import {
  Flex,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";

export default function Splash() {
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Image boxSize="100px" mb={10} src="/logo.png" alt="FlokX Logo" />
    </Flex>
  );
}
