import React, { useRef } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  useToast,
  Image,
} from "@chakra-ui/react";
import { auth } from '../firebase'
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useLocation } from 'react-router-dom'

export default function Login() {
  const emialEl = useRef(null);
  const passwordEl = useRef(null);
  const toast = useToast();

  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate()
  const location = useLocation()
  const redirectPath = location.state?.path || '/'

  console.log('redirect path', redirectPath)

  function loginFunction(e) {

    setLoading(true)
    e.preventDefault();

    const loginCredentials = {
      email: emialEl.current.value,
      password: passwordEl.current.value,
    };

    signInWithEmailAndPassword(auth, loginCredentials.email, loginCredentials.password)
      .then(() => {
        console.log('LOGGED IN', redirectPath)
        navigate(redirectPath, { replace: true })
      })
      .catch(err => {
        setLoading(false)
        toast({
          title: JSON.stringify(err),
          status: "error",
          isClosable: true,
        });
      })
  }

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Image boxSize="100px" mb={10} src="/logo.png" alt="FlokX Logo" />
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input ref={emialEl} type="email" />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input ref={passwordEl} type="password" />
            </FormControl>
            <Stack spacing={10}>
              <Button
                bg={"#008069"}
                color={"white"}
                _hover={{
                  bg: "#01B39B",
                }}
                mt={10}
                onClick={(e) => loginFunction(e)}
                isLoading={loading}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
