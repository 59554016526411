import { PURGE } from 'redux-persist'

const INITIAL_STATE = {
    user: null,
    queryDate: '',
    herdsListeners: {},
}

export const GET_USER = 'GET_USER'
export const GET_QUERY_DATE = 'GETQUERY_DATE'
export const GET_HERDS_LISTENERS = 'GET_HERDS_LISTENERS'
export const ADD_HERDS_LISTENERS = 'ADD_HERDS_LISTENERS'
export const REMOVE_HERDS_LISTENERS = 'REMOVE_HERDS_LISTENERS'

export default function yiming(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_USER:
            return { ...state, user: action.payload.user }
        case GET_QUERY_DATE:
            return { ...state, queryDate: action.payload.queryDate }
        case GET_HERDS_LISTENERS:
            return { ...state, herdsListeners: action.payload.herdsListeners }
        case ADD_HERDS_LISTENERS:
            console.log("ADD_HERDS_LISTENERS", action.payload)
            return {
                ...state, herdsListeners: {
                    ...state.herdsListeners,
                    [action.payload.sessionId]: action.payload.herdListener
                }
            }
        case REMOVE_HERDS_LISTENERS:
            const { [action.payload.sessionId]: value, ...without } = state.herdsListeners
            return {
                ...state, herdsListeners: without
            }
        case PURGE:
            return INITIAL_STATE
        default:
            return state
    }
}