import { PURGE } from 'redux-persist';

export const GET_HERDS = 'GET_HERDS'
export const GET_GOATS = 'GET_GOATS'
export const GET_FLOCK = 'GET_FLOCK'
export const GET_MEDIA = 'GET_MEDIA'

const INITIAL_STATE = {
  herds: [],
  herdsJSON: {},
  goats: {},
  goatsJSON: {},
  flock: {},
  flockJSON: {},
  media: {},
  mediaJSON: {},
};

export default function userRedux(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_HERDS:
      return {
        ...state,
        herds: {
          ...state.herds, [action.payload.sessionId]: action.payload.herds,
        },
        herdsJSON: {
          ...state.herdsJSON, [action.payload.sessionId]: action.payload.herdsJSON,
        }
      }
    case GET_GOATS:
      return {
        ...state,
        goats: {
          ...state.goats, [action.payload.herdId]: action.payload.goats,
        },
        goatsJSON: {
          ...state.goatsJSON, [action.payload.herdId]: action.payload.goatsJSON,
        }
      }
    case GET_FLOCK:
      return {
        ...state,
        flock: {
          ...state.flock, [action.payload.herdId]: action.payload.flock,
        },
        flockJSON: {
          ...state.flockJSON, [action.payload.herdId]: action.payload.flockJSON,
        }
      }
    case GET_MEDIA:
      // console.log("GET MEDIA" , action.payload)
      return {
        ...state,
        media: {
          ...state.media, [action.payload.herdId]: action.payload.media,
        },
        mediaJSON: {
          ...state.mediaJSON, [action.payload.herdId]: action.payload.mediaJSON,
        },
      }
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
