import React, { useRef, useEffect, useState, useMemo, useCallback } from "react";
import {
  Flex,
  Box,
  Input,
  InputGroup,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  useToast,
  Image,
  Center,
  Divider,
  Stat,
  StatLabel,
  StatNumber,
  SimpleGrid,
  theme,
  InputRightElement,
  Select
} from "@chakra-ui/react";

import { Table, Tr, Th, Td, Tb } from "../components/Table";
import AnnotationTableSkeleton from "../components/AnnotationTableSkeleton";
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { GET_MEDIA } from '../redux/contentRedux'

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/base.css'
import '@inovua/reactdatagrid-community/theme/green-light.css'

import StarRatings from "react-star-ratings";

const OPTIONS = [
  'perfect',
  'front',
  'full',
  'head',
  'left',
  'right',
]


function capitalise(input) {
  try {
    return input.charAt(0).toUpperCase() + input.slice(1);
  } catch (error) {

  }
  return input
}

function Annotation(props) {

  const toast = useToast()

  const [loading, setLoading] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [taskId, setTaskId] = useState(0)
  const [data, setData] = useState([])
  const [dataJSON, setDataJSON] = useState({})
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeId, setActiveId] = useState('')
  const [changes, setChanges] = useState({
    angle: '',
    rating: 0
  })

  const fetchMedia = () => {

    console.log("UID", props.user.user.uid)

    if (taskId !== 0) {
      setLoading(true)
      fetch('https://flok-90c44.el.r.appspot.com/getMediaFromTask', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          taskId
        })
      })
        .then(res => {
          return res.json()
        })
        .then(res => {
          setActiveIndex(0)
          setActiveId('')
          setChanges({
            angle: '',
            rating: 0
          })
          setData(Object.values(res['mediaJSON']))
          setDataJSON(res['mediaJSON'])
          setLoading(false)
        })
        .catch(err => {
          console.log("*********", err)
          setLoading(false)
          toast({
            title: 'Failed to fetch. Please refresh the page!',
            status: "error",
            isClosable: true,
          });
        })
    }
    else {
      toast({
        title: 'Task ID is empty!',
        status: "warning",
        isClosable: true,
      });
    }
  }

  const submitChanges = () => {
    if (changes['angle'] === '') {
      toast({
        title: 'Angle cannot be empty!',
        status: "warning",
        isClosable: true,
      });
    }
    else if (!changes['rating']) {
      toast({
        title: 'Rating cannot be empty!',
        status: "warning",
        isClosable: true,
      });
    }
    else {

      setUpdateLoading(true)

      fetch('https://flok-90c44.el.r.appspot.com/updateMedia', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: activeId,
          rating: changes['rating'],
          angle: changes['angle'],
          uid: props.user.user.uid
        })
      })
        .then(res => {
          return res.json()
        })
        .then(res => {
          if (res['status'] === 'success') {
            let newData = { ...dataJSON }
            newData[activeId] = {
              ...newData[activeId],
              rating: changes['rating'],
              angle: changes['angle']
            }
            setDataJSON(newData)
            setData(Object.values(newData))

            toast({
              title: 'Rated successfully!',
              status: "success",
              isClosable: true,
            })
          }
          else {
            toast({
              title: 'Server Error! Please try again',
              status: "warning",
              isClosable: true,
            })
          }
          setUpdateLoading(false)
        })
        .catch(() => {
          toast({
            title: 'Internet Error! Please try again',
            status: "warning",
            isClosable: true,
          })
          setUpdateLoading(false)
        })
    }
  }

  // useEffect(() => {
  //   if (Object.keys(dataJSON).length > 0 && activeId !== '') {
  //     console.log("RATING", dataJSON[activeId]['rating'])
  //   }
  // }, [dataJSON])

  const rowStyle = ({ data }) => {
    var styleObj = {}
    if (data['id'] === activeId) {
      styleObj = {
        backgroundColor: 'rgba(255,165,0, 0.2)',
        color: 'black',
      }
    }
    else if (!data['rating']) {
      styleObj = {
        backgroundColor: 'rgba(220,20,60, 0.15)',
        color: 'black',
      }
    }
    else if (data['rating']) {
      styleObj = {
        backgroundColor: 'rgba(0, 171, 85, 0.2)',
        color: 'black',
      }
    }
    return styleObj
  }

  return (
    <Flex w={'100vw'} h={"100vh"} bg={useColorModeValue("gray.100", "gray.800")}>
      <Box flex="1" display='flex' flexDirection={"column"} py={2} px={5}>
        <Heading size="lg" mb={2}>
          Input Task ID 👇
        </Heading>
        <InputGroup w='35%' >
          <Input
            bg='gray.300'
            type='number'
            focusBorderColor="teal.500"
            _placeholder={{ color: 'black' }}
            placeholder='Please enter Task ID'
            mb={4}
            onChange={(event) => {
              if (event.target.value !== '') {
                setTaskId(parseInt(event.target.value))
              }
              else {
                setTaskId(0)
              }
            }}
          />
          <InputRightElement width='4.5rem'>
            <Button h='1.75rem' size='sm' colorScheme="teal" variant="solid"
              isLoading={loading}
              onClick={(e) => {
                e.preventDefault()
                fetchMedia()
              }}
            >
              Fetch
            </Button>
          </InputRightElement>
        </InputGroup>
        <Heading size={"md"} mb={4}>
          Media Information {activeIndex > 0 ? '# ' + activeIndex : ''}
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={{ base: 5, lg: 3 }}
          mb={5}
        >
          <StatsCard title={"🙍🏾 Taken By"} stat={activeId !== '' ? dataJSON[activeId]['takenBy'] : '-'} />
          <StatsCard title={"📷 Angle"} stat={activeId !== '' ? capitalise(dataJSON[activeId]['angle']) : '-'} />
        </SimpleGrid>
        <Divider orientation="horizontal" />

        <AnimalAnnotationTab />
      </Box>
      <Center height="auto">
        <Divider orientation="vertical" />
      </Center>
      <Box flex="1">
        <Flex
          flex={1}
          minH={"100vh"}
          align={"center"}
          justify={"center"}
          flexDirection='column'
          bg={useColorModeValue("gray.100", "gray.800")}
        >
          <Image
            objectFit='cover'
            src={activeId !== '' ? dataJSON[activeId]['mediaUrl'] : ''}
            h={"75vh"}
            borderRadius={10}
          />

          {activeId !== '' &&
            <Stack
              direction="row"
              spacing={4}
              align="center"
              justifyContent='center'
              mt={5}
              zIndex={1000}
              w='100%'
              pr={5}
              pl={5}
            >
              <StarRatings
                rating={Object.keys(changes).length > 0 ? changes['rating'] : 0}
                starRatedColor="teal"
                starHoverColor='gold'
                changeRating={(rating, name) => {
                  console.log("RATING", rating)
                  setChanges({ ...changes, rating: rating })
                }}
                numberOfStars={5}
                starDimension='30px'
                name='rating'
              />

              <Divider orientation="vertical" />

              <Select
                isRequired={true}
                colorScheme='teal' bg='gray.300' focusBorderColor="teal.500" w='30%' placeholder='Select option'
                value={Object.keys(changes).length > 0 ? changes['angle'] : ''}
                onChange={(event) => {
                  // console.log("EVENT ***", event.target.value, typeof(event.target.value))
                  setChanges({ ...changes, angle: event.target.value })
                }}
              >
                {OPTIONS.map((option, index) => (
                  <option key={index} value={option}>{capitalise(option)}</option>
                ))}
              </Select>

              <Divider h={5} orientation="vertical" />

              <Button colorScheme="teal" variant="solid"
                isLoading={updateLoading}
                onClick={(event) => {
                  event.preventDefault()
                  submitChanges()
                }}
              >
                Submit
              </Button>
            </Stack>}

          {/* <Stack
            direction="row"
            spacing={4}
            align="center"
            mt={5}
            zIndex={1000}
          >
            <Button colorScheme="teal" variant="outline">
              Previous Photo
            </Button>
            <Button colorScheme="teal" variant="solid">
              Next Photo
            </Button>
          </Stack> */}

        </Flex>
      </Box>
    </Flex>
  );

  function AnimalAnnotationTab() {

    const columns = [
      {
        name: 'taskId', header: 'index', minWidth: 50, defaultFlex: 1,
        header: <Th>Task ID</Th>,
        headerProps: {
          style: {
            backgroundColor: theme.colors.gray['50'],
          }
        },
        render: ({ value }) => <Tb fontWeight="medium">#{value}</Tb>,
        cellProps: {
          style: {
            borderBottomWidth: "1px", borderBottomColor: theme.colors.gray['200'],
            width: '100%'
          }
        },
      },
      ///////////////////////////////
      {
        name: 'angle', header: 'Angle', defaultFlex: 1,
        header: <Th>Angle</Th>,
        headerProps: {
          style: {
            // height: '40px', width: '100%',
            backgroundColor: theme.colors.gray['50'],
          }
        },
        render: ({ value }) => <Tb textTransform='capitalize' fontWeight="medium">{value} </Tb>,
      },
      ///////////////////////////
      {
        name: 'takenBy', header: 'Taken By', defaultFlex: 2,
        header: <Th>Taken By</Th>,
        headerProps: {
          style: {
            backgroundColor: theme.colors.gray['50'],
          }
        },
        render: ({ value }) => <Tb fontWeight="medium">{value}</Tb>,
      },
      //////////////////////////
      {
        name: 'id', header: 'Annotate', defaultFlex: 2,
        header: <Th>Annotate</Th>,
        headerProps: {
          style: {
            backgroundColor: theme.colors.gray['50'],
          }
        },
        textAlign: 'center',
        render: ({ value }) => <Tb sx={{ height: '60px' }} fontWeight="medium">
          <Button
            backgroundColor="gray.900"
            color="white"
            fontWeight="medium"
            _hover={{ bg: "gray.700" }}
            _active={{
              bg: "gray.800",
              transform: "scale(0.95)",
            }}
            onClick={() => {
              setActiveIndex(dataJSON[value]['index'])
              setActiveId(value)
              setChanges({
                rating: dataJSON[value]['rating'] ? dataJSON[value]['rating'] : 0,
                angle: dataJSON[value]['angle'],
              })
            }}
          >
            Annotate
          </Button>
        </Tb>,
      },
    ]

    if (!loading) {
      return (
        <ReactDataGrid
          idProperty="id"
          columns={columns}
          dataSource={data}
          rowHeight={60}
          rowStyle={rowStyle}
          style={{
            minHeight: '30vh',
            width: '100%',
            backgroundColor: 'white',
            borderRadius: 8,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
          }}
        />
      )
    }

    return (
      <AnnotationTableSkeleton />
    )
  }

}


function StatsCard(props) {
  const { title, stat } = props;
  return (
    <Stat
      px={{ base: 4, md: 2 }}
      py={"2"}
      shadow={"md"}
      border={"1px solid"}
      borderColor={useColorModeValue("gray.800", "gray.500")}
      rounded={"lg"}
    >
      <StatLabel fontWeight={"medium"} isTruncated>
        {title}
      </StatLabel>
      <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
        {stat}
      </StatNumber>
    </Stat>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    content: state.content
  };
}
export default connect(mapStateToProps)(Annotation)
