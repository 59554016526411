import React from "react";
import { Box, Skeleton } from "@chakra-ui/react";
import { Table, Tr, Th, Td } from "./Table";

const SkeletonRow = ({ width }) => (
    <Box as="tr">
        <Td>
            <Skeleton height="10px" w={width} my={4} />
        </Td>
        <Td>
            <Skeleton height="10px" w={width} my={4} />
        </Td>
        <Td>
            <Skeleton height="10px" w={width} my={4} />
        </Td>
        <Td>
            <Skeleton height="10px" w={width} my={4} />
        </Td>
    </Box>
);

const AnnotationTableSkeleton = () => {
    return (
        <Table width="100%">
            <thead>
                <Tr>
                    <Th>Animal Number</Th>
                    <Th>Photo Angle Type</Th>
                    <Th>Annotation Status</Th>
                    <Th>Annotate</Th>
                </Tr>
            </thead>
            <tbody>
                <SkeletonRow width="75px" />
                <SkeletonRow width="125px" />
                <SkeletonRow width="50px" />
            </tbody>
        </Table>
    );
};

export default AnnotationTableSkeleton
